
const layouts = {
    mosaic: {
        className: 'if-mosaic',
        items: 5
    },

    threeInRow: {
        className: 'if-three-in-row',
        items: 3
    },

    sixInRow: {
        className: 'if-six-in-row',
        items: 6
    }
}

export default layouts;
